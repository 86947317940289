/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, Card, CardActions, Divider, Link, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import ButtonRow from 'components/course_tile/ButtonRow'
import Caption from 'components/course_tile/Caption'
import CoursePreview from 'components/course_tile/CoursePreview'
import useCourseMutation from 'components/editable/useCourseMutation'
import { currentPortal } from 'components/materialTheme/commonTheme'
import useUrls from 'hooks/useUrls'
import { addOrUpdateCourse } from 'pages/my_content/redux/coursesSlice'

import { cardMediaQueries } from './mediaQueries'

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '2px',
        padding: '0',
        border: `1px solid`,
        background: theme.palette.background.card
    },
    standardCard: {
        border: 'none',
        flexShrink: 0,
        ...cardMediaQueries(theme)
    },
    extendedCard: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1
    },
    cardActions: {
        overflow: 'hidden',
        padding: '0 8px 16px 8px'
    },
    courseNotPublished: {
        borderColor: theme.palette.primary.main
    },
    coursePublished: {
        borderColor: theme.palette.background.card
    },
    contentCourse: {
        display: 'flex',
        width: 340,
        flexDirection: 'column',
        justifyContent: 'space-between'
    },

    contentOverview: {
        width: '100%',
        padding: theme.spacing(1)
    }
}))

export default function CourseContentCourseTile({
    children,
    course,
    showTutorialLink = false
}: {
    children?: JSX.Element
    course: Course
    showTutorialLink?: boolean
}) {
    const classes = useStyles()
    const urls = useUrls()
    const userIsAuthenticated = true
    const dispatch = useDispatch()
    const { mutate: patchCourse } = useCourseMutation()
    const triggerMutation = useCallback(
        ({ id, changes }, options) =>
            // @ts-ignore
            patchCourse(
                { id, changes },
                {
                    ...options,
                    onSuccess: (newCourse: Course) => {
                        dispatch(addOrUpdateCourse({ ...course, ...changes, thumbnail: newCourse.thumbnail }))
                        if (options?.onSuccess) {
                            options.onSuccess()
                        }
                    }
                }
            ),
        [course, dispatch, patchCourse]
    )

    const { data: tutorialCourseID } = useQuery({
        queryKey: ['ConfigEntry', 'tutorialCourseID'],
        queryFn: () => axios.get(urls.configEntry('drg.TutorialCourseID')).then(res => res.data.config),
        enabled: currentPortal() === 'drg' && showTutorialLink
    })

    return (
        <Card
            className={clsx(
                classes.card,
                course.status === 'published' ? classes.coursePublished : classes.courseNotPublished
            )}
            data-testid="courseTile"
            elevation={3}
        >
            <div className={classes.standardCard}>
                <CoursePreview course={course} showProgress={userIsAuthenticated} triggerMutation={triggerMutation} />
                <Caption course={course} triggerMutation={triggerMutation} />
                <CardActions className={classes.cardActions}>
                    {course.id && (
                        <ButtonRow
                            course={course}
                            userIsAuthenticated={userIsAuthenticated}
                            triggerMutation={triggerMutation}
                        />
                    )}
                </CardActions>
                {showTutorialLink && tutorialCourseID && currentPortal() === 'drg' && (
                    <Box px={1} pb={2} display="grid" gap={1}>
                        <Link href={urls.courseDescription(tutorialCourseID)}>{gettext('Tutorial')}</Link>
                        <div>
                            <Typography variant="body2">
                                <span>{gettext('Help')}: </span>
                                <Link href="mailto:conrad@drg.de">conrad@drg.de</Link>
                            </Typography>
                        </div>
                    </Box>
                )}
            </div>

            {children && (
                <div className={classes.extendedCard}>
                    <Divider orientation="vertical" />
                    <div className={classes.contentOverview}>{children}</div>
                </div>
            )}
        </Card>
    )
}
